import React, { useState } from 'react';

import { Button, Card, useTheme } from '@involve-software/uikit';

import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';

import {
  PaymentType,
  SelectMethodPaySession,
  getSelectMethodPaySession,
} from 'api/payment/selectMethod';
import { PaymentStatus } from 'api/payment/status';

import ExpiryTimer from 'components/ExpiryTimer';

import { ReactComponent as CaretDown } from 'assets/icons/caret.svg';
import { PaymentsType } from 'types/payment';
import { formatCreditCardNumber16 } from 'utils/formatting';
import { useCountdown } from 'utils/hooks/useCountdown';
import { useToggleSnackbar } from 'utils/hooks/useToggleSnackbar';

import ExpiryStatusBlock from './components/ExpiryStatusBlock';
import * as Styled from './index.styled';

const CardTransferPayment = () => {
  const {
    amount,
    recipient,
    recipientName,
    currency,
    returnUrl,
    paymentId,
    expiresIn,
    status,
    type,
  } = useRouteLoaderData('selectMethod') as SelectMethodPaySession;

  const { t } = useTranslation('cardTransfer');

  const { paymentId: token } = useParams();

  const navigate = useNavigate();

  const toggleSnackbar = useToggleSnackbar();

  const [isVisibleFAQInfo, setIsVisibleFAQInfo] = useState(false);
  const [confirmPaymentDialogOpen, setConfirmPaymentDialogOpen] =
    useState(false);

  const { icons } = useTheme();

  const [countdown] = useCountdown(expiresIn ?? 0);

  const toggleVisibleFAQInfo = () => setIsVisibleFAQInfo((prev) => !prev);

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);

    toggleSnackbar({
      type: 'success',
      message: t('common:copied'),
    });
  };

  const checkPaymentStatus = async () => {
    try {
      const { data } = await getSelectMethodPaySession(token!);

      if (data.status === PaymentStatus.SUCCESS && data.successUrl) {
        window.location.href = data.successUrl;
        return;
      }

      if (data.status === PaymentStatus.REJECTED && data.failedUrl) {
        window.location.href = data.failedUrl;
        return;
      }

      let searchString = `?status=${data.status}&type=${PaymentsType.SELECT_METHOD}`;

      if (data.returnUrl) {
        searchString += `&returnUrl=${encodeURIComponent(data.returnUrl)}`;
      }

      navigate({
        pathname: `/result-page/${token}`,
        search: searchString,
      });
    } catch (e) {
      toggleSnackbar({
        type: 'error',
        message: t('common:error'),
      });
    }
  };

  if (expiresIn && countdown <= 0 && status === PaymentStatus.IN_PROGRESS) {
    return <ExpiryStatusBlock />;
  }

  return (
    <Styled.SelectMethodPayment>
      <Styled.BankCardBlock>
        <Card.Heading>
          <Styled.BankCardHeadingTitle>
            <icons.card.component />
            <Card.Title>{t('cardTransfer:receiver')}</Card.Title>
          </Styled.BankCardHeadingTitle>
          {expiresIn && (
            <ExpiryTimer secondsLeft={expiresIn} countdown={countdown} />
          )}
        </Card.Heading>
        <Card.Content $dividerDisabled>
          {recipientName && (
            <Styled.BankCardItem>
              <Styled.BankCardItemLabel>
                {t('cardTransfer:cardOwner')}
              </Styled.BankCardItemLabel>
              <Styled.BankCardItemValue>
                {recipientName}
                <icons.actions.copy.component
                  onClick={() => copyText(recipientName)}
                />
              </Styled.BankCardItemValue>
            </Styled.BankCardItem>
          )}
          {recipient && (
            <Styled.BankCardItem>
              {type === PaymentType.IBAN && (
                <>
                  <Styled.BankCardItemLabel>IBAN</Styled.BankCardItemLabel>
                  <Styled.BankCardItemValue>
                    {recipient}
                    <icons.actions.copy.component
                      onClick={() => copyText(recipient)}
                    />
                  </Styled.BankCardItemValue>
                </>
              )}
              {type === PaymentType.CARD && (
                <>
                  <Styled.BankCardItemLabel>
                    {t('cardTransfer:cardNumber')}
                  </Styled.BankCardItemLabel>
                  <Styled.BankCardItemValue>
                    {formatCreditCardNumber16(recipient)}
                    <icons.actions.copy.component
                      onClick={() => copyText(recipient)}
                    />
                  </Styled.BankCardItemValue>
                </>
              )}
            </Styled.BankCardItem>
          )}
        </Card.Content>
      </Styled.BankCardBlock>
      <Styled.InvoiceBlock>
        <Styled.Subtitle>
          <icons.invoice.component />
          {t('common:invoice')} № {paymentId}
        </Styled.Subtitle>
        <Styled.InvoiceTotal>
          <label>{t('cardTransfer:totalSum')}</label>
          <p>
            {amount} <span>{currency.alias}</span>
          </p>
        </Styled.InvoiceTotal>
      </Styled.InvoiceBlock>
      <Styled.WarningAlert
        variant="warning"
        title={t('cardTransfer:important')}
      >
        <Trans i18nKey={'cardTransfer:guide.step1'}>
          Send the <b>exact amount specified</b> for a quick payment credit.
          Funds sent via a <b>repeated transfer</b> will be lost.
        </Trans>
      </Styled.WarningAlert>
      <Styled.AdditionalInfo>
        <Styled.AdditionalInfoTrigger
          onClick={toggleVisibleFAQInfo}
          active={isVisibleFAQInfo}
        >
          <Styled.Subtitle>
            <icons.question.component />
            {t('cardTransfer:howToPay')}
          </Styled.Subtitle>
          <CaretDown />
        </Styled.AdditionalInfoTrigger>

        {isVisibleFAQInfo && (
          <Styled.AdditionalInfoList>
            <Styled.AdditionalInfoItem>
              <span>1.</span>
              {t('cardTransfer:howToPayGuide.step1')}
            </Styled.AdditionalInfoItem>
            <Styled.AdditionalInfoItem>
              <span>2.</span>
              {t('cardTransfer:howToPayGuide.step2')}
            </Styled.AdditionalInfoItem>
            <Styled.AdditionalInfoItem>
              <span>3.</span>
              {type === PaymentType.IBAN &&
                t('cardTransfer:howToPayGuide.step3.iban')}
              {type === PaymentType.CARD &&
                t('cardTransfer:howToPayGuide.step3.card')}
            </Styled.AdditionalInfoItem>
            <Styled.AdditionalInfoItem>
              <span>4.</span>
              {t('cardTransfer:howToPayGuide.step4')}
            </Styled.AdditionalInfoItem>
            <Styled.AdditionalInfoItem>
              <span>5.</span>
              {t('cardTransfer:howToPayGuide.step5')}
            </Styled.AdditionalInfoItem>
          </Styled.AdditionalInfoList>
        )}
      </Styled.AdditionalInfo>

      <Styled.ButtonGroup>
        <Button fullWidth onClick={() => setConfirmPaymentDialogOpen(true)}>
          {t('cardTransfer:payButton')}
        </Button>
        {returnUrl && (
          <a href={returnUrl}>
            <Button fullWidth variant="outlined">
              {t('cardTransfer:returnButton')}
            </Button>
          </a>
        )}
      </Styled.ButtonGroup>
      <Styled.ConfirmPaymentDialog
        title={t('cardTransfer:confirmDialog.title', {
          invoiceId: paymentId,
        })}
        open={confirmPaymentDialogOpen}
        onClose={() => setConfirmPaymentDialogOpen(false)}
      >
        <p>
          {type === PaymentType.CARD &&
            t('cardTransfer:confirmDialog.transferIsDone')}
          {type === PaymentType.IBAN &&
            t('cardTransfer:confirmDialog.ibanTransferIsDone')}{' '}
          <span>{formatCreditCardNumber16(recipient || '')}</span>.
        </p>
        <p>{t('cardTransfer:confirmDialog.pressTheButton')}</p>
        <Styled.ConfirmPaymentButton fullWidth onClick={checkPaymentStatus}>
          {t('cardTransfer:confirmDialog.confirm')}
        </Styled.ConfirmPaymentButton>
      </Styled.ConfirmPaymentDialog>
    </Styled.SelectMethodPayment>
  );
};

export default CardTransferPayment;
