import React, { useMemo } from 'react';

import { Alert, useTheme } from '@involve-software/uikit';

import { Trans, useTranslation } from 'react-i18next';

import 'nprogress/nprogress.css';
import useNavigationLoadingBar from 'utils/hooks/useNavigationLoadingBar';

import Footer from './Footer';
import Header from './Header';
import * as Styled from './index.styled';

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const { t } = useTranslation('common');

  useNavigationLoadingBar();

  const isKvitum = useMemo(
    () =>
      theme.name === 'kvitum' && window.location.hostname?.includes('kvitum'),
    [theme.name]
  );

  return (
    <Styled.Container>
      <Header />
      {isKvitum && (
        <main
          style={{
            display: 'block',
            paddingBottom: theme.spacing.primitives['s-20'],
            flex: 0,
            maxWidth: 920,
            width: '100%',
            paddingTop: theme.spacing.primitives['s-20'],
          }}
        >
          <Alert title={t('qostiqTransition.title')}>
            <Trans ns="common" i18nKey="common:qostiqTransition.description">
              Kvitum is moving to
              <a href="https://qostiq.com" target={'_blank'} rel="noreferrer">
                new domain
              </a>
            </Trans>
          </Alert>
        </main>
      )}
      <Styled.Content>{children}</Styled.Content>
      <Footer />
    </Styled.Container>
  );
};

export default Layout;
